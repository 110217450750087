:root {
  --font-smallest: 11px;
  --font-small: 12px;
  --font-normal: 12px;
  --font-medium: 14px;
  --font-large: 16px;
  --font-larger: 24px;
  --font-largest: 32px;

  --light-gray: #f0f3f6;
  --medium-gray: #d3d8dd;
  --dark-gray: #97a0af;
  --darker-gray: #42526e;
  --default-dark-gray: #505f79;
  --default-font-color: #061938;
  --default-purple: #7324f2;
  --default-grey-color: #a7afbb;
  --default-tag-color: #e78f1a;
  --progress-color: #ffb905;
  --course-result-color: #fff1df;
  --incorrect-color: #df0028;
}

* {
  font-family: Inter, system-ui, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 13px;
}

button * {
  pointer-events: none;
}

button[disabled] {
  background-color: gray !important;
  cursor: not-allowed !important;
}

.footer {
  height: 15px;
  width: 100vw;
  background-color: #212631;
}

.content {
  max-width: 1300px;
  width: 100vw;
  margin: 0 auto;
  padding: 0 15px;
}

body {
  margin: 0;
  font-family: Inter, system-ui, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.certificate-container {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.certificate-container > #certificate > iframe {
  width: 85vw;
  height: 85vh;
}

.close-modal {
  position: absolute;
  top: 0;
  right: 0;

  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
}

.close-modal:hover {
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.2);
}

#global-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 20;

  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--default-purple);
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
